<template>
    <div class="video-page">
        <div class="left">
            <menu-tree :showChannel="false" ref="mTree" class="tree" style="flex:1;" @nodeClick="nodeClick"></menu-tree>
            <el-card style="width:320px;height:200px;">
                <h3>请先选择通道回放时间：</h3>
                <el-form label-width="70px" label-position="right">
                    <el-form-item label="查询日期">
                        <el-date-picker :clearable="false" value-format="yyyy-MM-dd" type="date" v-model="startDay"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="查询时间">
                        <el-time-picker :clearable="false" value-format="HH:mm:ss" is-range style="width:220px;" v-model="timeRange"></el-time-picker>
                    </el-form-item>
                </el-form>
            </el-card>
        </div>
        <div class="videos-wrapper">
            <videoPlayer
                v-for="video in videos"
                ref="video"
                :key="video.blockId"
                :isHistory="true"
                :class="videoLayoutClass(video)"
                :params="getParams(video)"
                class="vCommon"
                :startTime="video.startEnd[0]"
                :endTime="video.startEnd[1]"
            ></videoPlayer>
        </div>

        <!-- 默认查询9个通道 -->
        <time-line ref="timeline" :startEnd="getTimes" :channelCount="16" @pushVideo="pushVideo"></time-line>
    </div>
</template>
<script>
    /**
     * 根据选择的时间及设备去查询历史视频资源列表，点击列表中的任意一项，播放对应选择的资源列表
     * 只能查询当天
     * 新方式，但是目前不一定可行
     */
    import videoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
    import menuTree from "../common/mTree";
    import TimeLine from "./timeline.vue";
    export default {
        name: "index",
        computed: {
            videoLayoutClass() {
                //:class="channelId === 1 && current === 6?'v_6_1':`v${current}`"
                return video => {
                    if (this.videos.length === 1) {
                        return "v1";
                    } else if (this.videos.length <= 4) {
                        return "v4";
                    } else if (this.videos.length <= 6) {
                        if (video.channelId == 1) {
                            return "v_6_1";
                        } else {
                            return "v6";
                        }
                    } else if (this.videos.length <= 9) {
                        return "v9";
                    } else {
                        return "v16";
                    }
                };
            },
            getTitle() {
                return video => {
                    return video.deviceId + "-" + video.channelId + "-" + video.startEnd[0] + "-" + video.startEnd[1];
                };
            },
            /**
             * @param clientId 设备编号
             * @param channelId 通道号
             * @param startTime  开始时间
             * @param endTime 结束时间
             */
            getParams() {
                return function(video) {
                    return {
                        clientId: video.deviceId,
                        channelId: video.channelId
                    };
                };
            },
            getTimes() {
                if (this.startDay && this.timeRange.length) {
                    return [this.startDay + " " + this.timeRange[0], this.startDay + " " + this.timeRange[1]];
                } else {
                    return [];
                }
            }
        },
        methods: {
            // 播放视频
            nodeClick(data) {
                if (data.type === 2) {
                    // 重置之前选中的视频窗口
                    this.resetVideo();
                    const begin = this.startDay + " " + this.timeRange[0],
                        end = this.startDay + " " + this.timeRange[1];
                    this.$refs.timeline.init({
                        deviceId: data.id,
                        startEnd: [begin, end]
                    });
                }
            },
            // 重置视频
            resetVideo() {
                this.videos = [];
            },
            pushVideo(data) {
                const itemIndex = this.videos.findIndex(v => v.channelId === data.channelId);
                let needPush = false;
                if (itemIndex != -1) {
                    if (this.videos[itemIndex].blockId != data.deviceId + data.channelId + data.startTime + data.endTime) {
                        needPush = true;
                    }
                    this.videos.splice(itemIndex, 1);
                } else {
                    needPush = true;
                }
                if (needPush) {
                    let startEnd = [new Date(data.startTime).Format("yyyyMMddhhmmss"), new Date(data.endTime).Format("yyyyMMddhhmmss")];
                    this.videos.push({
                        deviceId: data.deviceId,
                        channelId: data.channelId,
                        id: data.deviceId + "_" + data.channelId,
                        startEnd: startEnd,
                        blockId: data.deviceId + data.channelId + data.startTime + data.endTime
                    });
                }
            }
        },
        created() {
            this.startDay = new Date().Format("yyyy-MM-dd");
            this.timeRange = ["00:00:00", new Date().Format("hh:mm:ss")];
        },
        components: {
            videoPlayer,
            menuTree,
            TimeLine
        },
        data() {
            return {
                loading: false,

                startDay: "",
                timeRange: [],
                videos: [] // 初始化的视频通道
            };
        }
    };
</script>
<style lang="scss" scoped>
    @import "../common/style.scss";
</style>
<style lang="scss" scoped>
    .left {
        width: 320px;
        height: 100%;
        background-color: white;
        display: flex;
        flex-flow: column nowrap;
    }
</style>
