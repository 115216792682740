<template>
    <div class="track_list">
        <div class="resize_box" v-bind:class="{ hiden: isHide }">
            <div class="table_box" v-loading="loading">
                <div class="timelineChart" ref="timelineChart" style="height:480px;"></div>
            </div>
        </div>
        <div class="hideBtn" @click="dropDownClick">
            <i class="dropDown iconfont icon-xiala1"></i>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    export default {
        props: {
            startEnd: Array,
            channelCount: Number
        },
        computed: {
            startTime() {
                if (this.startEnd && this.startEnd[0]) {
                    return this.startEnd[0];
                } else {
                    return new Date().Format("yyyy-MM-dd hh:mm:ss");
                }
            },
            isReady() {
                if (this.startEnd && this.startEnd.length && this.deviceId && this.channelCount) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        data() {
            return {
                loading: false,

                categories: [],
                deviceId: "",

                chart: null,

                isHide: true
            };
        },
        methods: {
            getResource() {
                if (this.isReady) {
                    // 查询所有的通道
                    this.$http
                        .post("/video/queryVideoList", {
                            alert: "00000000",
                            clientId: this.deviceId,
                            startTime: this.startEnd[0],
                            endTime: this.startEnd[1],
                            channelId: "0",
                            mediaType: "00",
                            streamType: "00",
                            saveType: "00"
                        })
                        .then(res => {
                            this.loading = false;
                            if (res && res && res.detail) {
                                const result = this.handleResult(res.detail);
                                this.drawChart(result);
                            }
                        });
                }
            },
            getCategories() {
                this.categories = [];
                // 查询16个通道的资源
                this.loading = true;
                for (let index = 0; index < this.channelCount; index++) {
                    this.categories.push(`通道${index + 1}`);
                }
                this.categories.reverse();
            },
            handleResult(values) {
                let result = [];
                values = values.filter(v => v.fileSize);
                if (values && values.length) {
                    values.forEach(v => {
                        const startTime = new Date(this.$util.fixedMacTime(v.startTime)).getTime();
                        const endTime = new Date(this.$util.fixedMacTime(v.endTime)).getTime();
                        result.push({
                            name: "资源时长",
                            value: [
                                v.channelId - 1,
                                startTime,
                                endTime,
                                endTime - startTime // duration
                            ]
                        });
                    });
                }
                return result;
            },
            renderItem(params, api) {
                var categoryIndex = api.value(0);
                var start = api.coord([api.value(1), 15 - categoryIndex]);
                var end = api.coord([api.value(2), categoryIndex]);
                var height = api.size([0, 1])[1] * 0.6;

                var rectShape = echarts.graphic.clipRectByRect(
                    {
                        x: start[0],
                        y: start[1] - height / 2,
                        width: end[0] - start[0],
                        height: height
                    },
                    {
                        x: params.coordSys.x,
                        y: params.coordSys.y,
                        width: params.coordSys.width,
                        height: params.coordSys.height
                    }
                );

                return (
                    rectShape && {
                        type: "rect",
                        shape: rectShape,
                        style: api.style()
                    }
                );
            },
            drawChart(data) {
                if (this.chart) {
                    const option = {
                        animation: false,
                        tooltip: {
                            formatter: function(params) {
                                return `<span>
	                                        <span>${params.marker}</span>
	                                        <span>通道${params.value[0] + 1}:</span>
	                                        <br />
	                                        <span>开始时间：</span>
	                                        <span>${new Date(params.value[1]).Format("hh:mm:ss")}</span>
	                                        <br />
	                                        <span>结束时间：</span>
	                                        <span>${new Date(params.value[2]).Format("hh:mm:ss")}</span>
	                                        <br />
	                                        <span>时长：</span>
	                                        <span>${(params.value[3] / (60 * 1000)).toFixed(2)}分</span>
	                                    </span>`;
                            }
                        },
                        title: {
                            text: "历史视频资源分布"
                        },
                        xAxis: {
                            position: "top",
                            splitLine: {
                                lineStyle: {
                                    color: ["#E9EDFF"]
                                }
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                lineStyle: {
                                    color: "#929ABA"
                                }
                            },
                            axisLabel: {
                                color: "#929ABA",
                                inside: false,
                                align: "center",
                                formatter: function(value) {
                                    return new Date(value).Format("hh:mm:ss");
                                }
                            },
                            min: new Date(this.startTime).getTime()
                        },
                        yAxis: {
                            data: this.categories
                        },
                        series: [
                            {
                                type: "custom",
                                renderItem: this.renderItem,
                                itemStyle: {
                                    opacity: 0.8,
                                    normal: {
                                        color: function(params) {
                                            if (params.data.play) {
                                                return "#CC0033";
                                            } else {
                                                return "#99CC33";
                                            }
                                        }
                                    }
                                },
                                encode: {
                                    x: [1, 2],
                                    y: 0
                                },
                                data
                            }
                        ]
                    };
                    this.chart.setOption(option);
                }
            },
            updateChart(e) {
                // 更新被点击的item的颜色
                let playItemId = e.value[0] + "_" + e.value[1] + "_" + e.value[2];
                let option = this.chart.getOption();
                option.series[0].data.forEach(v => {
                    const value = v.value;
                    const itemId = value[0] + "_" + value[1] + "_" + value[2];
                    if (e.value[0] == value[0]) {
                        if (playItemId == itemId) {
                            v.play = !v.play;
                        } else {
                            v.play = false;
                        }
                    }
                });
                this.chart.setOption(option);
            },
            dropDownClick() {
                this.isHide = !this.isHide;
            },
            init(option) {
                this.deviceId = option.deviceId;
                if (this.isHide) {
                    this.dropDownClick();
                }
                if (this.chart) {
                    this.chart.clear();
                }
                this.getCategories();
                this.getResource();
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.chart = echarts.init(this.$refs.timelineChart);
                const _this = this;
                this.chart.on("click", function(e) {
                    _this.$emit("pushVideo", {
                        channelId: e.value[0] + 1,
                        startTime: e.value[1],
                        endTime: e.value[2],
                        deviceId: _this.deviceId
                    });
                    _this.updateChart(e);
                });
            });
        },
        destroyed() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
        }
    };
</script>
<style type="text/css" lang="scss" scoped="track_list">
    .track_list {
        position: fixed;
        bottom: 0;
        left: 245px;
        right: 0;
        background: #fff;

        .resize_box {
            overflow: hidden;
            transition: all 0.5s;
            height: 500px;

            &.hiden {
                height: 0 !important;

                + .hideBtn {
                    .dropDown {
                        transform: rotate(180deg);
                    }
                }
            }

            .table_box {
                height: 100%;
                padding: 10px;
            }
        }

        .hideBtn {
            position: absolute;
            top: -27px;
            text-align: center;
            left: 50%;
            margin-left: -35px;
            z-index: 998;
            width: 71px;
            height: 27px;
            background: rgb(64, 119, 188);
            border-radius: 10px 10px 0px 0px;
            cursor: pointer;

            i {
                line-height: 27px;
                color: #fff;
                font-size: 27px;
            }

            .dropDown {
                margin: 7px 0 0 0px;
                transform: rotate(0deg);
                transition: all 0.5s;
            }
        }
    }
</style>
